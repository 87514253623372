<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1250" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType==='detail' ? '关闭' : '取消'}}</a-button>
      </template>
      <div class="box-container" style="margin-top: 10px;">
        <div class="box-container-inner" style="margin: -25px -30px 10px -30px">
          <div class="table-header">
            <h3 class="table-title">调度员巡查完成情况</h3>
            <div class="table-btns">
              <a-button @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
              <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
            </div>
          </div>
          <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
            <span slot="takeovertime" slot-scope="value">
              <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
                <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
              </a-tooltip>
            </span>
            <span slot="eventtype" slot-scope="value">{{value===901?"报警":"异常"}}</span>
            <span slot="isfinished" slot-scope="value">
              <a-tag :color="value==0?'green':'red'">{{value==0?'及时完成巡查':'未及时完成巡查'}}</a-tag>
            </span>
          </a-table>
          <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
            <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template>
          </a-pagination>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex'
import pagination from "@/mixins/pagination";
import {exportYardPatrolExcel, getYardPatrolListByCondition} from "A/employeeStatus";
export default {
  mixins: [pagination],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'detail'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        height: '650px',
        overflowY: 'auto',
      },
      modalVisible: false,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '姓名',
          dataIndex: 'username',
          key: 'username',
          ellipsis: true,
        },
        {
          title: '账号',
          dataIndex: 'useraccount',
          key: 'useraccount',
          ellipsis: true,
        },
        {
          title: '接班时间',
          dataIndex: 'takeovertime',
          key: 'takeovertime',
          ellipsis: true,
          scopedSlots: { customRender: 'takeovertime' },
        },
        // {
        //   title: '巡查方式',
        //   dataIndex: 'patroltype',
        //   key: 'patroltype',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'patroltype' },
        // },
        {
          title: '巡查完成情况',
          dataIndex: 'isfinished',
          key: 'isfinished',
          ellipsis: true,
          scopedSlots: { customRender: 'isfinished' },
        },
      ],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      return '调度员巡查完成情况'
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.modalVisible = true;
        this.initDetail();
      }
    },
    modalVisible(val) {
      if (!val) {
        this.$emit('get-operation-result', 'success', '操作成功');
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    initDetail() {
      if (this.detailData) {
        this.$nextTick(() => {
          this.getTableData(true)
        })
      }
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.detailData,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getYardPatrolListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    customRow(record, index) {
      return {
        on: { // 事件
          click: () => {
            this.$router.push({
              name: 'addOrEditPatrolsRecord',
              params: {
                monitorpointnum: record.monitorpointnum,
                patrolrecordid: record.patrolrecordid,
                patrolshift: record.patrolshift,
                modalTitle:"巡查记录查询"
              }
            })
          }
        }
      }
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportEmployeeStatus()
      }).catch(()=>{
      });
    },
    exportEmployeeStatus(){
      let params = {
        ...this.detailData,
      }
      this.showLoading();
      exportYardPatrolExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>