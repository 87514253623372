<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1250" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType==='detail' ? '关闭' : '取消'}}</a-button>
      </template>
      <div class="box-container" style="margin-top: 10px;">
        <div class="box-container-inner" style="margin: -25px -30px 10px -30px">
          <div class="table-header">
            <h3 class="table-title">调度员工作记录</h3>
            <div class="table-btns">
              <a-button @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
              <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
            </div>
          </div>
          <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="shifttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
            <span slot="takeovertime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
            <span slot="nextshifttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
            <span slot="shiftstatus" slot-scope="value">
            <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'完成':'未完成'}}</a-tag>
          </span>
            <span slot="shiftnum" slot-scope="value,record">
            <span>{{patrolShifts[record.shiftnum-1].value+(record.takeovernum?"->"+patrolShifts[record.takeovernum-1].value:'')}}</span>
          </span>
          </a-table>
          <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
            <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template>
          </a-pagination>
        </div>
      </div>
      <a-modal v-model="shiftVisible" title="交接班历史" class="mymodal" :dialogStyle="dialogStyle">
        <template slot="footer">
          <a-button @click="shiftVisible=false">取消</a-button>
        </template>
        <a-divider style="font-weight: bold;margin-bottom: 30px;margin-top: -10px">器材交接</a-divider>
        <div style="padding: 20px 10px 10px 25px;border-style:solid solid solid solid;border-color:skyblue; border-width: 3px;margin-top: -28px">
          <a-row>
            <a-col :span="12">器材清单</a-col>
            <a-col :span="12">器材确认情况</a-col>
          </a-row>
          <a-row>
            <a-col :span="12"><textarea style="width: 200px;" cols="26" rows="2" v-model="queryParams.equiplist" :disabled="true"></textarea></a-col>
            <a-col :span="12"><textarea style="width: 200px;" cols="26" rows="2" v-model="queryParams.equipconfirm" :disabled="true"></textarea></a-col>
          </a-row>
        </div>
        <a-divider style="margin-top: 25px;font-weight: bold;margin-bottom: 30px;margin-top: 0px">消防主机状况</a-divider>
        <div style="padding: 20px 10px 10px 25px;border-style:solid solid solid solid;border-color:skyblue; border-width: 3px;margin-top: -28px">
          <a-row>
            <a-col :span="8">主备用电源</a-col>
            <a-col :span="8">联动运行状态</a-col>
            <a-col :span="8">设备运行状态</a-col>
          </a-row>
          <a-row>
            <a-col :span="8"><a-select style="width: 120px" v-model="queryParams.power" :disabled="true">
              <a-select-option :value="0">主电源</a-select-option>
              <a-select-option :value="1">备用电源</a-select-option>
              <a-select-option :value="2">备电故障</a-select-option>
              <a-select-option :value="3">电源故障</a-select-option>
            </a-select></a-col>
            <a-col :span="8"><a-select style="width: 120px" v-model="queryParams.linkagestatus" :disabled="true">
              <a-select-option :value="0">手动</a-select-option>
              <a-select-option :value="1">自动</a-select-option>
            </a-select></a-col>
            <a-col :span="8"><a-select style="width: 120px;" v-model="queryParams.devicestatus" :disabled="true">
              <a-select-option :value="0">正常</a-select-option>
              <a-select-option :value="1">不正常</a-select-option>
            </a-select></a-col>
          </a-row>
          <a-row style="margin-top: 10px">
            <a-col :span="24">跟进及处理情况</a-col>
          </a-row>
          <a-row>
            <a-col :span="16"><textarea style="width: 265px;" cols="38" rows="3" v-model="queryParams.description" :disabled="true"></textarea></a-col>
            <a-col :span="8">
              <div style="border-style:solid solid solid solid;border-color:gainsboro; border-width: 1px;width: 120px">
                <a-form-model layout="inline" style="margin: -12px -10px 16px 5px;">
                  <a-form-model-item label="火警" style="height: 15px"><a-input style="width: 60px;height: 20px" v-model="queryParams.firenum" :disabled="true"></a-input></a-form-model-item>
                  <a-form-model-item label="故障" style="height: 15px"><a-input style="width: 60px;height: 20px" v-model="queryParams.faultnum" :disabled="true"></a-input></a-form-model-item>
                  <a-form-model-item label="隔离" style="height: 15px"><a-input style="width: 60px;height: 20px" v-model="queryParams.insulatenum" :disabled="true"></a-input></a-form-model-item>
                </a-form-model>
              </div>
            </a-col>
          </a-row>
        </div>
        <div style="border-style:solid solid solid solid;border-color:skyblue; border-width: 3px;margin-top: 10px;">
          <a-divider style="font-weight: bold;margin-top: 5px">交班说明</a-divider>
          <div style="padding: 0px 10px 10px 25px;">
            <textarea style="width: 412px;" cols="60" rows="2" v-model="queryParams.shiftdesc" :disabled="true"></textarea>
            <a-divider style="font-weight: bold;margin-top: 3px">交接班状态</a-divider>
            <a-row>
              <a-col :span="2">状态</a-col>
              <a-col :span="2">班次</a-col>
              <a-col :span="8">时间</a-col>
              <a-col :span="12">姓名</a-col>
              <!--            <a-col :span="5">账号</a-col>-->
            </a-row>
            <a-row>
              <a-col :span="2"><span>{{'交班'}}</span></a-col>
              <a-col :span="2"><span>{{queryParams.shiftnum}}</span></a-col>
              <a-col :span="8"><span>{{queryParams.shifttime}}</span></a-col>
              <a-col :span="12"><span>{{queryParams.shiftman}}</span></a-col>
              <!--            <a-col :span="5"><span>{{queryParams.shiftaccount}}</span></a-col>-->
            </a-row>
            <a-row>
              <a-col :span="2"><span>{{'接班'}}</span></a-col>
              <a-col :span="2"><span>{{queryParams.takeovernum}}</span></a-col>
              <a-col :span="8"><span>{{queryParams.takeovertime}}</span></a-col>
              <a-col :span="12"><span>{{queryParams.takeoverman}}</span></a-col>
              <!--            <a-col :span="5"><span>{{queryParams.takeoveraccount}}</span></a-col>-->
            </a-row>
          </div>
        </div>
      </a-modal>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex'
import pagination from "@/mixins/pagination";
import {exportYardPersonExcel, getYardPersonListByCondition} from "A/employeeStatus";
import {patrolShifts} from "@/json/wlwhistory";
import {getShiftDataListByCondition} from "A/patrol";
export default {
  mixins: [pagination],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'detail'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        height: '650px',
        overflowY: 'auto',
      },
      shiftVisible:false,
      dialogStyle:{
        top:"10px"
      },
      modalVisible: false,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      patrolShifts,
      shiftStatusList:[
        {"value":"未完成"},
        {"value":"完成"},
      ],
      queryParam:{
        monitorpointnum:'',
        monitorpointname:'',
        eventtype:'',
        recordman:'',
        useraccount:'',
        description:'',
        starttime:'',
        endtime:'',
      },
      queryParams:{
        monitorpointname:'',
        shiftman:'',
        takeoverman:'',
        shiftstatus:'',
        starttime:'',
        endtime:'',
        monitorpointnum:'',
        equiplist:'',
        shiftnum:null,
        takeovernum:null,
        equipconfirm:'齐备',
        power:0,
        linkagestatus:0,
        devicestatus:0,
        shifttime:'',
        takeovertime:'',
        description:'',
        shiftaccount:'',
        takeoveraccount:'',
        shiftdesc:'',
        firenum:0,
        faultnum:0,
        insulatenum:0,
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '班次',
          dataIndex: 'shiftnum',
          key: 'shiftnum',
          ellipsis: true,
          scopedSlots: { customRender: 'shiftnum' }
        },
        {
          title: '交班时间',
          dataIndex: 'shifttime',
          key: 'shifttime',
          ellipsis: true,
          scopedSlots: { customRender: 'shifttime' }
        },
        {
          title: '接班时间',
          dataIndex: 'takeovertime',
          key: 'takeovertime',
          ellipsis: true,
          scopedSlots: { customRender: 'takeovertime' }
        },
        {
          title: '交班人',
          dataIndex: 'shiftman',
          key: 'shiftman',
          ellipsis: true,
        },
        {
          title: '接班人',
          dataIndex: 'takeoverman',
          key: 'takeoverman',
          ellipsis: true,
        },
        {
          title: '交接班状态',
          dataIndex: 'shiftstatus',
          key: 'shiftstatus',
          ellipsis: true,
          scopedSlots: { customRender: 'shiftstatus' }
        },
      ],
      detailModalVisible: false,
      detailModalShowType: '',
      detailModalDetailData: null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      return '调度员工作记录'
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.modalVisible = true;
        this.initDetail();
      }
    },
    modalVisible(val) {
      if (!val) {
        this.$emit('get-operation-result', 'success', '操作成功');
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    initDetail() {
      if (this.detailData) {
        this.$nextTick(() => {
          for(let key in this.queryParam) {
            this.queryParam[key] = this.detailData[key]||'';
          }
          this.getTableData(true)
        })
      }
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParam,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getYardPersonListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    customRow(record, index) {
      return {
        on: { // 事件
          click: () => {
            // this.detailModalDetailData=record
            // this.detailModalShowType="detail"
            // this.detailModalVisible=true
            this.shiftVisible=true
            let params={
              monitorpointnum:record.monitorpointnum,
              shifttime: record.shifttime,
              shiftid:record.shiftid,
            }
            getShiftDataListByCondition(params).then(res=>{
              if(res&&res.returncode=='0'){
                if(res.item.length>0){
                  this.queryParams.shiftstatus=res.item[0].shiftstatus
                  this.queryParams.equiplist=res.item[0].equiplist
                  this.queryParams.equipconfirm=res.item[0].equipconfirm
                  this.queryParams.power=res.item[0].power
                  this.queryParams.linkagestatus=res.item[0].linkagestatus
                  this.queryParams.devicestatus=res.item[0].devicestatus
                  this.queryParams.shifttime=res.item[0].shifttime
                  this.queryParams.description=res.item[0].description
                  this.queryParams.shiftaccount=res.item[0].shiftaccount
                  this.queryParams.shiftman=res.item[0].shiftman
                  this.queryParams.shiftnum=res.item[0].shiftnum?patrolShifts[res.item[0].shiftnum-1].value:null
                  this.queryParams.takeovernum=res.item[0].takeovernum?patrolShifts[res.item[0].takeovernum-1].value:null
                  this.queryParams.shifttime=res.item[0].shifttime?moment(res.item[0].shifttime,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"):null
                  this.isShift=false
                  this.queryParams.shiftdesc=res.item[0].shiftdesc
                  this.queryParams.takeoveraccount=res.item[0].takeoveraccount
                  this.queryParams.takeoverman=res.item[0].takeoverman
                  this.queryParams.takeovertime=res.item[0].takeovertime?moment(res.item[0].takeovertime,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"):null
                  this.queryParams.firenum=res.item[0].firenum
                  this.queryParams.faultnum=res.item[0].faultnum
                  this.queryParams.insulatenum=res.item[0].insulatenum
                }
              }
            })
          }
        }
      }
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportEmployeeStatus()
      }).catch(()=>{
      });
    },
    exportEmployeeStatus(){
      let params = {
        ...this.queryParam,
      }
      this.showLoading();
      exportYardPersonExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>