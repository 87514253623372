<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1250" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType==='detail' ? '关闭' : '取消'}}</a-button>
      </template>
      <div class="box-container" style="margin-top: 10px;">
        <div class="box-container-inner" style="margin: -25px -30px 10px -30px">
          <div class="table-header">
            <h3 class="table-title">人工巡查</h3>
            <div class="table-btns">
              <a-button @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
              <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
            </div>
          </div>
          <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="inspection_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
            <span slot="inspection_exception" slot-scope="value">
            <a-tag :color="value=='0'?'green':'red'">{{value==="0"?"正常":"异常"}}</a-tag>
          </span>
            <span slot="inspection_interval" slot-scope="value">{{timeHandle(value)}}</span>
          </a-table>
          <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
            <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template>
          </a-pagination>
        </div>
      </div>
    </a-modal>
    <detail-modal :visible.sync="detailModalVisible" :detail-data="detailModalDetailData" @get-operation-result="getTableData"></detail-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex'
import pagination from "@/mixins/pagination";
import {exportManualPatrolExcel, getManualPatrolListByCondition} from "A/employeeStatus";
import DetailModal from "./DetailModal.vue";
export default {
  components: {DetailModal},
  mixins: [pagination],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'detail'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        height: '650px',
        overflowY: 'auto',
      },
      modalVisible: false,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '姓名',
          dataIndex: 'username',
          key: 'username',
          ellipsis: true,
        },
        {
          title: '账号',
          dataIndex: 'useraccount',
          key: 'useraccount',
          ellipsis: true,
        },
        {
          title: '巡查线路名称',
          dataIndex: 'patroldailytemplatename',
          key: 'patroldailytemplatename',
          ellipsis: true,
        },
        {
          title: '巡查开始时间',
          dataIndex: 'inspection_time',
          key: 'inspection_time',
          ellipsis: true,
          scopedSlots: { customRender: 'inspection_time' },
        },
        {
          title: '巡查用时',
          dataIndex: 'inspection_interval',
          key: 'inspection_interval',
          ellipsis: true,
          scopedSlots: { customRender: 'inspection_interval' },
        },
        {
          title: '巡查状态',
          dataIndex: 'inspection_exception',
          key: 'inspection_exception',
          ellipsis: true,
          scopedSlots: { customRender: 'inspection_exception' },
        },
      ],
      detailModalVisible: false,
      detailModalShowType: '',
      detailModalDetailData: null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      return '人工巡查人员'
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.modalVisible = true;
        this.initDetail();
      }
    },
    modalVisible(val) {
      if (!val) {
        this.$emit('get-operation-result', 'success', '操作成功');
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    initDetail() {
      if (this.detailData) {
        this.$nextTick(() => {
          this.getTableData(true)
        })
      }
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.detailData,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getManualPatrolListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    customRow(record, index) {
      return {
        on: { // 事件
          click: () => {
            this.detailModalDetailData=record
            this.detailModalShowType="detail"
            this.detailModalVisible=true
          }
        }
      }
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportEmployeeStatus()
      }).catch(()=>{
      });
    },
    exportEmployeeStatus(){
      let params = {
        ...this.detailData,
      }
      this.showLoading();
      exportManualPatrolExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
    timeHandle(time){
      let returnTime;
      let day;
      let hour;
      let minutes;
      let seconds;
      if(time>86400) {
        day = Math.floor(time / 86400);
        returnTime=day + "天";
        if (time % 86400 > 3600) {
          hour = Math.floor(time % 86400 / 3600);
          returnTime+=hour + "时";
          if (time % 86400 % 3600 > 60) {
            minutes = Math.floor(time % 86400 % 3600 / 60);
            returnTime+=minutes + "分";
            seconds = Math.floor(time % 86400 % 3600 % 60);
            returnTime+=seconds + "秒";
          } else {
            seconds = Math.floor(time % 86400 % 3600 % 60);
            returnTime+=seconds + "秒";
          }
        } else {
          if (time % 86400 > 60) {
            minutes = Math.floor(time % 86400 / 60);
            returnTime+=minutes + "分";
            seconds = Math.floor(time % 86400 % 60);
            returnTime+=seconds + "秒";
          } else {
            seconds = Math.floor(time % 86400);
            returnTime+=seconds + "秒";
          }
        }
      }else if(time>=3600&&time<86400){
        let hour = Math.floor(time % 86400 / 3600);
        returnTime=hour + "时";
        if (time % 3600 > 60) {
          minutes = Math.floor(time % 3600 / 60);
          returnTime+=minutes + "分";
          seconds = Math.floor(time % 3600 % 60);
          returnTime+=seconds + "秒";
        } else {
          seconds = Math.floor(time % 3600 % 60);
          returnTime+=seconds + "秒";
        }
      }else if(time>=60&&time<3600){
        minutes = Math.floor(time / 60);
        returnTime=minutes + "分";
        seconds = Math.floor(time % 60);
        returnTime+=seconds + "秒";
      }else{
        returnTime=time + "秒";
      }
      return returnTime
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>