<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1250" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType==='detail' ? '关闭' : '取消'}}</a-button>
      </template>
      <div class="box-container" style="margin-top: 10px;">
        <div class="box-container-inner" style="margin: -25px -30px 10px -30px">
          <div class="table-header">
            <h3 class="table-title">维保人员</h3>
            <div class="table-btns">
              <a-button @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
              <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
            </div>
          </div>
          <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
            <span slot="register_time" slot-scope="value">
              <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
                <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
              </a-tooltip>
            </span>
            <span slot="finish_time" slot-scope="value">
              <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
                <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
              </a-tooltip>
            </span>
            <span slot="equipment_type" slot-scope="value,record">{{value?value.length==2?value.substring(0,1)==0?deviceCodeList[value.replace('0','')-1].dicvalue:deviceCodeList[value-1].dicvalue:'':''}}</span>
            <span slot="order_exception" slot-scope="value">
              <a-tag :color="orderExceptionColors[value]||''">{{orderException[value]||''}}</a-tag>
            </span>
            <span slot="duration" slot-scope="value">{{timeHandle(value)}}</span>
            <span slot="content" slot-scope="value,record">
              <span :title="detailData.work_type==='保养'&&record.hasOwnProperty('maintenance_order_id')?value?maintenancePeriodList[value].dicvalue:'':value">{{detailData.work_type==='保养'&&record.hasOwnProperty('maintenance_order_id')?value?maintenancePeriodList[value].dicvalue:'':value}}</span>
            </span>
          </a-table>
          <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
            <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template>
          </a-pagination>
        </div>
      </div>
      <detail-modal :visible.sync="detailModalVisible" :detail-data="detailModalDetailData" @get-operation-result="getTableData"></detail-modal>
      <repair-detail-modal :visible.sync="repairModalVisible" :detail-data="repairModalDetailData" @get-operation-result="getTableData"></repair-detail-modal>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex'
import pagination from "@/mixins/pagination";
import {exportMaintenancePersonExcel, getMaintenancePersonListByCondition} from "A/employeeStatus";
import {getCache} from "U";
import {orderException, orderExceptionColors} from "@/json/maintenance";
import DetailModal from "./DetailModal.vue";
import RepairDetailModal from "./RepairDetailModal.vue";
export default {
  components: {RepairDetailModal, DetailModal},
  mixins: [pagination],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'detail'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        height: '650px',
        overflowY: 'auto',
      },
      modalVisible: false,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      gradeList :["不合格","合格","良好","优秀"],
      orderException,
      orderExceptionColors,
      deviceCodeList:[],
      maintenancePeriodList:[],
      queryParams:{
        monitorpointnum:'',
        monitorpointname:'',
        person:'',
        person_name:'',
        work_type:'保养',
        property_audit_grade:'',
        rolename:'',
        equipment_type:'',
        equipment_num:'',
        order_exception:'',
        start_time:'',
        end_time:'',
        starttime:'',
        endtime:'',
        recordman:'',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '姓名',
          dataIndex: 'person_name',
          key: 'person_name',
          ellipsis: true,
          width: 70,
        },
        {
          title: '账号',
          dataIndex: 'person',
          key: 'person',
          ellipsis: true,
          width: 100,
        },
        {
          title: '签到时间',
          dataIndex: 'register_time',
          key: 'register_time',
          ellipsis: true,
          width: 140,
          scopedSlots: { customRender: 'register_time' },
        },
        {
          title: '完成时间',
          dataIndex: 'finish_time',
          key: 'finish_time',
          ellipsis: true,
          width: 140,
          scopedSlots: { customRender: 'finish_time' },
        },
        {
          title: '工作时长',
          dataIndex: 'duration',
          key: 'duration',
          ellipsis: true,
          width: 100,
          scopedSlots: { customRender: 'duration' },
        },
        {
          title: '设备编号',
          dataIndex: 'equipment_num',
          key: 'equipment_num',
          ellipsis: true,
        },
        {
          title: '设备类型',
          dataIndex: 'equipment_type',
          key: 'equipment_type',
          ellipsis: true,
          scopedSlots: { customRender: 'equipment_type' },
        },
        {
          title: '设备名称',
          dataIndex: 'equipment_name',
          key: 'equipment_name',
          ellipsis: true,
        },
        {
          title: '作业内容',
          dataIndex: 'content',
          key: 'content',
          ellipsis: true,
          scopedSlots: { customRender: 'content' },
        },
        {
          title: '作业时间状态',
          dataIndex: 'order_exception',
          key: 'order_exception',
          ellipsis: true,
          width: 100,
          scopedSlots: { customRender: 'order_exception' },
        },
        {
          title: '物业评价',
          dataIndex: 'property_audit_evaluation',
          key: 'property_audit_evaluation',
          ellipsis: true,
          width: 70,
        },
      ],
      detailModalVisible: false,
      detailModalShowType: '',
      detailModalDetailData: null,
      repairModalVisible: false,
      repairModalShowType: '',
      repairModalDetailData: null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      return '维保人员'
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.modalVisible = true;
        this.initDetail();
      }
    },
    modalVisible(val) {
      if (!val) {
        this.$emit('get-operation-result', 'success', '操作成功');
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.deviceCodeList = dictionary.deviceCodeList
      this.maintenancePeriodList = dictionary.maintenancePeriodList
    }
  },
  methods: {
    initDetail() {
      if (this.detailData) {
        this.$nextTick(() => {
          for(let key in this.queryParams) {
            this.queryParams[key] = this.detailData[key]||'';
          }
          this.getTableData(true)
        })
      }
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getMaintenancePersonListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    customRow(record, index) {
      return {
        on: { // 事件
          click: () => {
            if (!record.hasOwnProperty("maintenance_order_id")) {
              this.repairModalDetailData = record
              this.repairModalShowType = "detail"
              this.repairModalVisible = true
            } else {
              this.detailModalDetailData = record
              this.detailModalShowType = "detail"
              this.detailModalVisible = true
            }
          }
        }
      }
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportEmployeeStatus()
      }).catch(()=>{
      });
    },
    exportEmployeeStatus(){
      let params = {
        ...this.queryParams,
      }
      this.showLoading();
      exportMaintenancePersonExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
    timeHandle(time){
      let returnTime;
      let day;
      let hour;
      let minutes;
      let seconds;
      if(time>86400) {
        day = Math.floor(time / 86400);
        returnTime=day + "天";
        if (time % 86400 > 3600) {
          hour = Math.floor(time % 86400 / 3600);
          returnTime+=hour + "时";
          if (time % 86400 % 3600 > 60) {
            minutes = Math.floor(time % 86400 % 3600 / 60);
            returnTime+=minutes + "分";
            seconds = Math.floor(time % 86400 % 3600 % 60);
            returnTime+=seconds + "秒";
          } else {
            seconds = Math.floor(time % 86400 % 3600 % 60);
            returnTime+=seconds + "秒";
          }
        } else {
          if (time % 86400 > 60) {
            minutes = Math.floor(time % 86400 / 60);
            returnTime+=minutes + "分";
            seconds = Math.floor(time % 86400 % 60);
            returnTime+=seconds + "秒";
          } else {
            seconds = Math.floor(time % 86400);
            returnTime+=seconds + "秒";
          }
        }
      }else if(time>=3600&&time<86400){
        let hour = Math.floor(time % 86400 / 3600);
        returnTime=hour + "时";
        if (time % 3600 > 60) {
          minutes = Math.floor(time % 3600 / 60);
          returnTime+=minutes + "分";
          seconds = Math.floor(time % 3600 % 60);
          returnTime+=seconds + "秒";
        } else {
          seconds = Math.floor(time % 3600 % 60);
          returnTime+=seconds + "秒";
        }
      }else if(time>=60&&time<3600){
        minutes = Math.floor(time / 60);
        returnTime=minutes + "分";
        seconds = Math.floor(time % 60);
        returnTime+=seconds + "秒";
      }else{
        returnTime=time + "秒";
      }
      return returnTime
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>